
    /* eslint-disable */
    /* tslint:disable */
    
    // generated by react-imported-component, DO NOT EDIT     
    import {assignImportedComponents} from 'react-imported-component/macro';
    undefined    
    
    // all your imports are defined here
    // all, even the ones you tried to hide in comments (that's the cost of making a very fast parser)
    // to remove any import from here
    // 1) use magic comment `import(/* client-side */ './myFile')` - and it will disappear
    // 2) use file filter to ignore specific locations (refer to the README - https://github.com/theKashey/react-imported-component/#server-side-auto-import)
    // 3) use .imported.js to control this table generation (refer to the README - https://github.com/theKashey/react-imported-component/#-imported-js)
    
    const applicationImports = assignImportedComponents([
      [() => import(/* webpackChunkName: "AdvisersFeedback" */'./scenes/AdvisersFeedback/AdvisersFeedback'), 'AdvisersFeedback', './src/scenes/AdvisersFeedback/AdvisersFeedback', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "AppContextual-Index" */'./components/AppContextual/AppContextual'), 'AppContextual-Index', './src/components/AppContextual/AppContextual', false] /* from ./src/components/AppContextual/index.js */,
      [() => import(/* webpackChunkName: "Articles-Article" */ /*  */'./scenes/Articles/ArticleScroll'), 'Articles-Article', './src/scenes/Articles/ArticleScroll', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "Articles-Latest" */'./scenes/Articles/Latest'), 'Articles-Latest', './src/scenes/Articles/Latest', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "Banks-Bank" */'./scenes/Banks/Bank'), 'Banks-Bank', './src/scenes/Banks/Bank', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "Banks-BankArticle" */'./scenes/Banks/BankArticle'), 'Banks-BankArticle', './src/scenes/Banks/BankArticle', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "Banks-BankArticleList" */'./scenes/Banks/BankArticleList'), 'Banks-BankArticleList', './src/scenes/Banks/BankArticleList', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "Banks-BankArticles" */'./components/Banks/BankArticles'), 'Banks-BankArticles', './src/components/Banks/BankArticles', false] /* from ./src/components/Banks/index.js */,
      [() => import(/* webpackChunkName: "Banks-BankHeader" */'./components/Banks/BankHeader'), 'Banks-BankHeader', './src/components/Banks/BankHeader', false] /* from ./src/components/Banks/index.js */,
      [() => import(/* webpackChunkName: "Banks-BankProducts" */'./scenes/Banks/BankProducts'), 'Banks-BankProducts', './src/scenes/Banks/BankProducts', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "Groups-Index" */'./scenes/Groups/Groups'), 'Groups-Index', './src/scenes/Groups/Groups', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "Home" */'./scenes/Home'), 'Home', './src/scenes/Home', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "IIC-ETFsHome" */'./scenes/IIC/Homes/ETFsHome'), 'IIC-ETFsHome', './src/scenes/IIC/Homes/ETFsHome', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "IIC-FundSheet", client-side */'./scenes/IIC/FundSheet'), 'IIC-FundSheet', './src/scenes/IIC/FundSheet', true] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "IIC-FundSheetById" */'./scenes/IIC/FundSheetById'), 'IIC-FundSheetById', './src/scenes/IIC/FundSheetById', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "IIC-FundsCategories" */'./scenes/IIC/Categories'), 'IIC-FundsCategories', './src/scenes/IIC/Categories', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "IIC-FundsCategory" */'./scenes/IIC/Category'), 'IIC-FundsCategory', './src/scenes/IIC/Category', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "IIC-FundsComparison" */'./scenes/IIC/Comparison'), 'IIC-FundsComparison', './src/scenes/IIC/Comparison', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "IIC-FundsHome" */'./scenes/IIC/Homes/FundsHome'), 'IIC-FundsHome', './src/scenes/IIC/Homes/FundsHome', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "IIC-FundsList" */'./scenes/IIC/FundsList'), 'IIC-FundsList', './src/scenes/IIC/FundsList', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "IIC-FundsManagementCompanies" */'./scenes/IIC/ManagementCompanies'), 'IIC-FundsManagementCompanies', './src/scenes/IIC/ManagementCompanies', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "IIC-FundsManagementCompany" */'./scenes/IIC/ManagementCompany'), 'IIC-FundsManagementCompany', './src/scenes/IIC/ManagementCompany', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "IIC-PlansHome" */'./scenes/IIC/Homes/PlansHome'), 'IIC-PlansHome', './src/scenes/IIC/Homes/PlansHome', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "Multimedia-Home" */'./scenes/Multimedia/MultimediaCommons'), 'Multimedia-Home', './src/scenes/Multimedia/MultimediaCommons', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "MyAuthorizations-Index" */'./scenes/MyAuthorizations/MyAuthorizations'), 'MyAuthorizations-Index', './src/scenes/MyAuthorizations/MyAuthorizations', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "MyComparisons-Index" */'./scenes/MyComparisons/MyComparisons'), 'MyComparisons-Index', './src/scenes/MyComparisons/MyComparisons', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "MyFinances-Favorites" */'./scenes/MyFinances/Favorites'), 'MyFinances-Favorites', './src/scenes/MyFinances/Favorites', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "MyFinances-Feed" */'./scenes/MyFinances/Feed'), 'MyFinances-Feed', './src/scenes/MyFinances/Feed', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "MyFinances-Panel" */'./scenes/MyFinances/Panel'), 'MyFinances-Panel', './src/scenes/MyFinances/Panel', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "OnBoarding-Index" */'./scenes/OnBoarding/OnBoarding'), 'OnBoarding-Index', './src/scenes/OnBoarding/OnBoarding', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "OnBoarding-Start" */'./scenes/OnBoarding/Start'), 'OnBoarding-Start', './src/scenes/OnBoarding/Start', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "ProductCheckout-ProductCheckout" */'./scenes/ProductCheckout/ProductCheckout'), 'ProductCheckout-ProductCheckout', './src/scenes/ProductCheckout/ProductCheckout', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "ProductCheckout-ProductSubscription" */'./scenes/ProductCheckout/ProductSubscription/ProductSubscriptionContainer'), 'ProductCheckout-ProductSubscription', './src/scenes/ProductCheckout/ProductSubscription/ProductSubscriptionContainer', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "ProductCheckout-SubscriptionConditions" */'./scenes/ProductCheckout/SubscriptionConditions/SubscriptionConditions'), 'ProductCheckout-SubscriptionConditions', './src/scenes/ProductCheckout/SubscriptionConditions/SubscriptionConditions', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "Products-ProductComparison" */'./scenes/Products/ProductComparison'), 'Products-ProductComparison', './src/scenes/Products/ProductComparison', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "Products-ProductQuestions" */'./scenes/Products/ProductQuestions'), 'Products-ProductQuestions', './src/scenes/Products/ProductQuestions', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "Products-ProductReview" */'./scenes/Products/ProductReview'), 'Products-ProductReview', './src/scenes/Products/ProductReview', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "Products-ProductReviews" */'./scenes/Products/ProductReviews'), 'Products-ProductReviews', './src/scenes/Products/ProductReviews', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "Products-ProductSheet" */'./scenes/Products/ProductSheet'), 'Products-ProductSheet', './src/scenes/Products/ProductSheet', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "Products-WriteReview" */'./scenes/Products/WriteReview'), 'Products-WriteReview', './src/scenes/Products/WriteReview', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "Profiles-Group" */'./scenes/Profiles/Group'), 'Profiles-Group', './src/scenes/Profiles/Group', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "Profiles-User" */'./scenes/Profiles/User/UserProfileLayout'), 'Profiles-User', './src/scenes/Profiles/User/UserProfileLayout', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "Storefronts-AlternativesAcademy" */'./scenes/Storefronts/Alternatives/AlternativesAcademy'), 'Storefronts-AlternativesAcademy', './src/scenes/Storefronts/Alternatives/AlternativesAcademy', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "Storefronts-AlternativesHome" */'./scenes/Storefronts/Alternatives/AlternativesHome'), 'Storefronts-AlternativesHome', './src/scenes/Storefronts/Alternatives/AlternativesHome', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "Storefronts-DigitalAcademy" */'./scenes/Storefronts/Digital/DigitalAcademy'), 'Storefronts-DigitalAcademy', './src/scenes/Storefronts/Digital/DigitalAcademy', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "Storefronts-DigitalHome" */'./scenes/Storefronts/Digital/DigitalHome'), 'Storefronts-DigitalHome', './src/scenes/Storefronts/Digital/DigitalHome', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "Storefronts-StorefrontArticle" */'./scenes/Storefronts/StorefrontArticle'), 'Storefronts-StorefrontArticle', './src/scenes/Storefronts/StorefrontArticle', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "Storefronts-StorefrontProductsList" */'./scenes/Storefronts/StorefrontProductsList'), 'Storefronts-StorefrontProductsList', './src/scenes/Storefronts/StorefrontProductsList', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "Storefronts-StorefrontsHome" */'./scenes/Storefronts/StorefrontsHome'), 'Storefronts-StorefrontsHome', './src/scenes/Storefronts/StorefrontsHome', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "StorefrontsHome-Index" */'./scenes/Storefronts/Storefront'), 'StorefrontsHome-Index', './src/scenes/Storefronts/Storefront', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "SuperComparison" */'./scenes/IIC/SuperComparison'), 'SuperComparison', './src/scenes/IIC/SuperComparison', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "TC-Reviews" */'@finect/tabular-components/Reviews'), 'TC-Reviews', '@finect/tabular-components/Reviews', false] /* from ./src/scenes/Products/ProductSheet/components/ProductReviews/ProductReviews.js */,
      [() => import(/* webpackChunkName: "TCO-FaviconMetatags" */'@finect/tabular-conversations/FaviconMetatags'), 'TCO-FaviconMetatags', '@finect/tabular-conversations/FaviconMetatags', false] /* from ./src/components/AppContextual/AppContextual.js */,
      [() => import(/* webpackChunkName: "Tag" */'./scenes/Tags/Tag'), 'Tag', './src/scenes/Tags/Tag', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "Tags" */'./scenes/Tags/TagsHome'), 'Tags', './src/scenes/Tags/TagsHome', false] /* from ./src/scenes/index.js */,
      [() => import(/* webpackChunkName: "components-Box" */'./components/Box/Box'), 'components-Box', './src/components/Box/Box', false] /* from ./src/components/Box/index.js */,
    ]);
    
    export default applicationImports;
    
    // @ts-ignore
    if (module.hot) {
       // these imports would make this module a parent for the imported modules.
       // but this is just a helper - so ignore(and accept!) all updates
       
       // @ts-ignore
       module.hot.accept(() => null);
    }    
    